<template>
  <div class="overflow-x-auto relative">
    <slot />

    <div
      v-if="loading"
      class="bg-surface-0/[.5] absolute flex h-full items-center justify-center right-0 top-0 w-full dark:bg-black/[.5]"
    >
      <ProgressSpinner
        stroke-width="8"
        style="height: 40px; width: 40px;"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{
  data?: any,
  loading?: boolean
}>(), {
  loading: false
})

provide('data', toRef(props, 'data'))
provide('loading', toRef(props, 'loading'))
</script>
