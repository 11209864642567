<template>
  <div class="gap-2 grid grid-cols-2 mb-2 md:grid-cols-3">
    <div class="flex flex-col justify-center font-bold overflow-hidden">{{ props.header }}</div>
    <div class="md:col-span-2">
      <Skeleton v-if="loading && !data" width="15rem" />

      <slot v-if="data" name="body" :data="data">
        {{ value }}
      </slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  field?: string
  header?: string
}>()

const data = inject<any>('data')
const loading = inject<boolean>('loading')

const value = computed(() => {
  if (!data.value) return
  if (!props.field) return
  return data.value[props.field]
})
</script>
